
import {computed, defineComponent, onMounted, ref} from "vue";
import {ISearchField} from "@/core/entity/ISearchField";
import {CLAIM_DECISION, OPERATOR} from "@/core/config/Constant";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import store from "@/store";
import Entities from "@/components/base/common/Entities.vue";
import {CompanySelect, ExaminerSelect, LoadPanel, SearchEntityEvent, TagSelect} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import InvoiceViewCard from "@/views/invoice/InvoiceViewCard.vue";
import Cards from "@/components/base/common/Cards.vue";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import BaseSelect from "@/components/base/select/BaseSelect.vue";

export default defineComponent({
  name: "Invoices",
  components: {BaseSelect, DateTimeFormat, KtDatatable, Cards, InvoiceViewCard, Entities},
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams('Invoice Search',
        [
          {link: false, router: '', text: 'Invoice Search'}
        ]
      )
    })
    const fields = ['all']
    const fieldSearch: ISearchField[] = [
      {column: 'clientId', type: 'SELECT', operator: OPERATOR.EQ, value: '', name: 'Client'},
      {column: 'policyNumber', type: 'TEXT', operator: OPERATOR.LIKE, value: '', name: 'Policy #'},
      {column: 'certificate', type: 'TEXT', operator: OPERATOR.LIKE, value: '', name: 'Certificate #'},
      {column: 'claimNumber', type: 'TEXT', operator: OPERATOR.LIKE, value: '', name: 'Claim #'},
      {column: 'invoiceNumber', type: 'TEXT', operator: OPERATOR.LIKE, value: '', name: 'Invoice #'},
      {column: 'firstName', type: 'TEXT', operator: OPERATOR.LIKE, value: '', name: 'First Name'},
      {column: 'lastName', type: 'TEXT', operator: OPERATOR.LIKE, value: '', name: 'Last Name'},
      {column: 'invoiceType', type: 'SELECT', operator: OPERATOR.EQ, value: '', name: 'Type'},
      {column: 'decisionStatus', type: 'SELECT', operator: OPERATOR.EQ, value: '', name: 'Claim Decision Status'},
      {column: 'invoiceStatus', type: 'SELECT', operator: OPERATOR.EQ, value: 'ACTIVE', name: 'Status'},
      {column: 'repriceStatus', type: 'SELECT', operator: OPERATOR.EQ, value: '', name: 'Reprice Status'},
      {column: 'adjudicationStatus', type: 'SELECT', operator: OPERATOR.EQ, value: '', name: 'Adjudication Status'},
      {column: 'examinerId', type: 'SELECT', operator: OPERATOR.EQ, value: '', name: 'Examiner'},
      {column: 'tags', type: 'TEXT', operator: OPERATOR.LIKE, value: '', name: 'Tag'},
      {column: 'ghipStatus', type: 'SELECT', operator: OPERATOR.EQ, value: '', name: 'GHIP Status'},
    ]
    const tableHeader = ref([
      {
        name: "Invoice#",
        key: "invoiceNumber",
        sortable: true,
      },
      {
        name: "Client",
        key: "client",
        sortable: true,
      },
      {
        name: "Policy#",
        key: "policyNumber",
        sortable: true,
      },
      {
        name: "Claim#",
        key: "claimNumber",
        sortable: true,
      },
      {
        name: "Insured",
        key: "insured",
        sortable: true,
      },
      {
        name: 'Billed',
        key: 'billed',
        sortable: true,
      },
      {
        name: 'DOS From',
        key: 'dos',
        sortable: true,
      },
      {
        name: 'DOS To',
        key: 'dos2',
        sortable: true,
      },

    ]);
    const invoiceViewPage = computed(() => store.state.InvoiceModule.invoiceViewPage)
    return {
      fields,
      fieldSearch,
      invoiceViewPage,
      ...CompanySelect(),
      ...SearchEntityEvent(Actions.INVOICE_SEARCH, fieldSearch, fields, [], 'invoiceView'),
      tableHeader,
      ...LoadPanel(),
      ...ExaminerSelect(),
      ...TagSelect('INVOICE'),
      CLAIM_DECISION,
    }
  }
})
