
import {defineComponent} from "vue";
import InvoiceStatus from "@/views/invoice/InvoiceStatus.vue";
import Row from "@/components/base/common/Row.vue";
import {LoadPanel} from "@/core/composite/composite";
import CurrencyFormat from "@/components/base/currency/CurrencyFormat.vue";
import InvoiceLink from "@/views/invoice/InvoiceLink.vue";
import InvoiceLabel from "@/views/invoice/InvoiceLabel.vue";
import OriginalInvoice from "@/views/invoice/OriginalInvoice.vue";
import StatementName from "@/views/invoice/StatementName.vue";

export default defineComponent({
  name: "InvoiceViewCard",
  components: {StatementName, OriginalInvoice, InvoiceLabel, InvoiceLink, CurrencyFormat, Row, InvoiceStatus},
  props: {
    invoiceView: {}
  },
  setup() {
    return {
      ...LoadPanel(),
    }
  }
})
